import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import Slider from "react-slick";
import swal from "sweetalert";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { showAlert } from "../../../../../components/SwalAlert/showAlert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_URLS, BASE_URL } from "../../../../../Utils";

const formatDate = (dateString) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

const PlayerReg = ({ isOpen, onClose }) => {
  //  / console.log("Hola In PlayerReg");
  const fileInputRef = useRef(null);
  const fileInputRefPlayer = useRef(null);
  const [bracketWinner, setBracketWinner] = useState([]);

  const [userinfo, setUserinfo] = useState(null);
  const [id, setId] = useState(userinfo !== null ? userinfo.id : null);
  const [imageName, setImageName] = useState("");
  const [isShowStats, setIsShowStats] = useState(false);
  const [isFreeAgent, setIsFreeAgent] = useState(false);
  const [bats, setBats] = useState("Yes");
  const [teamListData, setTeamListData] = useState([]);
  const [teamId, setTeamId] = useState();
  const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);
  const [teamNameforEmail, setTeamNameforEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");

  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    EmailId: "",
    Password: "",
    PlayerNumber: "",
    isAnonymous: isFreeAgent,
    Bating: "",
    TeamId: teamId,
    DOB: dateOfBirth || "",
    State: "",
    Bats: "",
    Throws: "",
    Position: "",
    Address: "",
    City: "",
    PhoneNumber: "",
    Comments: "",
    PlayerPhoto: "", // Added
    License: "", // Added
    PlayLevel: 0,
    isAvailable: false,
  });
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: true,
    fade: true,
  };
  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  const handleButtonClickPlayer = (e) => {
    e.preventDefault();
    fileInputRefPlayer.current.click();
  };
  const fetchLatestBracketWinner = async () => {
    try {
      const response = await axios.get(API_URLS.Get_Latest_BracketWinners);
      setBracketWinner(response.data.data.result);

      //console.log("bracket winner Data", response.data.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectTeamChange = (e) => {
    const value = e.target.value;
    const isFreeAgent = value === "Register Yourself as A Free Agent" ? 1 : 0;
    // Find the selected team's ID
    const selectedTeam = teamListData.find((team) => team.TeamName === value);
    const teamId = selectedTeam ? selectedTeam.TeamId : 0;

    setTeamNameforEmail(selectedTeam ? selectedTeam.TeamName : "Free Agent");
    setTeamId(teamId);
    setIsFreeAgent(isFreeAgent);
    setFormData((prevState) => ({
      ...prevState,
      isAnonymous: isFreeAgent,
      TeamId: teamId,
    }));
  };
  // console.log("team name", teamName);
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    if (name === "PlayLevel") {
      const updatedValue = name === "PlayLevel" ? parseInt(value, 10) : value;

      setFormData((prevState) => ({
        ...prevState,
        [name]: updatedValue,
      }));
    }

    if (name === "ddlState") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked, // Set true if checked, false if unchecked
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      FirstName,
      LastName,
      EmailId,
      Password,
      PlayerNumber,
      State,
      City,
      Address,
      PhoneNumber,
      PlayerPhoto,
      License,
      isAnonymous,
      PlayLevel,
      isAvailable,
    } = formData;
    if (!FirstName.trim()) {
      showAlert("Info", "Please Enter First Name.", "info");
      return;
    }
    if (!LastName.trim()) {
      showAlert("Info", "Please Enter Last Name.", "info");
      return;
    }
    if (!EmailId.trim()) {
      showAlert("Info", "Please Enter Email ID.", "info");
      return;
    }
    try {
      const emailCheckUrl = `${
        API_URLS.CHECK_PLAYER_EXIST
      }?Email=${encodeURIComponent(EmailId)}`;

      const emailCheckResponse = await fetch(emailCheckUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!emailCheckResponse.ok) {
        throw new Error("Email check failed");
      }

      const emailCheckResult = await emailCheckResponse.json();
      if (emailCheckResult.data.result[0].PlayerExists === 1) {
        showAlert(
          "warning",
          "This Player E-Mail already exists. Please use a different E-Mail .",
          "warning"
        );
        return;
      }
    } catch (error) {
      showAlert("Error checking email", error.message, "error");
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(EmailId.trim())) {
      showAlert("Info", "Please Enter a valid Email ID.", "info");
      return;
    }
    if (!Password.trim()) {
      showAlert("Info", "Please Enter Password.", "info");
      return;
    }
    if (!PlayerNumber.trim()) {
      showAlert("Info", "Please Enter Jersey Number.", "info");
      return;
    }

    if (PlayLevel === 0) {
      showAlert(
        "Info",
        "Please select the Level of Play for the League.",
        "info"
      );
      return;
    }

    const formDataWithTeamId = {
      ...formData,
      TeamId: teamId ? teamId : 0,
    };
    try {
      const url = id != null ? API_URLS.UPDATE_Player : API_URLS.ADD_Player;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataWithTeamId),
      });
      if (response.ok) {
        const data = await response.json();

        if (data && data.status === "success") {
          if (id != null) {
            showAlert("Success", "Player Updated Successfully", "success");
          } else {
            showAlert("Success", "Player Added Successfully", "success");
          }
          const emailParams = {
            first_name: FirstName,
            last_name: LastName,
            email_id: EmailId,
            phone_number: PhoneNumber,
            isanonymous: isAnonymous,
            Team: teamNameforEmail,
          };

          try {
            const emailResponse = await fetch(API_URLS.ADD_PLAYER_SEND_EMAIL, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(emailParams),
            });

            if (emailResponse.ok) {
              console.log("Email sent successfully");
            } else {
              console.log("Error sending email");
            }
          } catch (error) {
            console.error("Error sending email:", error);
          }
        } else {
          showAlert("Error", "Error Adding Player. Try Again", "error");
        }
      } else {
        showAlert(" Server Error", "Unable to process the request.", "error");
      }
    } catch (error) {
      console.error("Error:", error);

      showAlert(
        " Error",
        "An error occurred while processing the request.",
        "error"
      );
    }
  };

  const customStyles = {
    content: {
      width: "100%", // Adjust this value to your desired width
      maxWidth: "1015.2px", // Optional: Set a maximum width
      // Center the modal horizontally
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",

      margin: "auto",

      // Add some padding
      // Add other styles as needed
    },
  };

  const handleImageChange = async (e) => {
    setIsRegisterDisabled(true);
    setTimeout(() => {
      setIsRegisterDisabled(false);
    }, 5000);
    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        let data = new FormData();
        data.append("playerImage", file);
        const response = await axios.post(API_URLS.UPLOAD_PLAYER_IMAGE, data);

        if (response.data.data.image.filename) {
          setFormData((prevState) => ({
            ...prevState,
            PlayerPhoto: response.data.data.image.filename,
          }));
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleDOBChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleLicenseImageChange = async (e) => {
    setIsRegisterDisabled(true);
    setTimeout(() => {
      setIsRegisterDisabled(false);
    }, 5000);
    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        let data = new FormData();
        data.append("licenseImage", file);
        const response = await axios.post(API_URLS.UPLOAD_LICENSE_IMAGE, data);

        if (response.data.data.image.filename) {
          setFormData((prevState) => ({
            ...prevState,
            License: response.data.data.image.filename,
          }));
        }
      }
    } catch (error) {
      console.error("Error uploading license image:", error);
    }
  };

  const getAllTeams = () => {
    const url = API_URLS.GET_ALL_TEAMS;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((data) => {
        if (
          data &&
          data.data &&
          Array.isArray(data.data.result) &&
          Array.isArray(data.data.result[0])
        ) {
          //console.log("teamList", data.data.result[0]);
          setTeamListData(data.data.result[0]);
        } else {
          swal("Unexpected data format:", data);
          throw new Error("Unexpected data format.");
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error.message);
      });
  };

  useEffect(() => {
    getAllTeams();
    fetchLatestBracketWinner();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Rules Modal"
      className="modal-content player orange-scrollbar"
      overlayClassName="modal-overlay"
      style={{ ...customStyles }}
    >
      <div
        className="registration_modal playerRegistration_modal "
        id="playerRegistration"
      >
        <div className="modal_body">
          <div className="slider_wrap">
            <div className="slick teamReg_slick">
              <Slider {...settings}>
                {bracketWinner.map((bracket, index) => (
                  <div key={index} className="slick_item">
                    <img
                      src={`${BASE_URL}/api/v1/files/BracketPhoto/${bracket.Photo}`}
                      alt={bracket.Detail}
                    />
                    <div className="content">
                      <h3>{bracket.Detail}</h3>
                      <span>{formatDate(bracket.ProAmPlayingDate)}</span>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          <div
            className="form_wrap teamReg_form orange-scrollbar"
            style={{
              overflowY: "auto",
              maxHeight: "600.2px",
              overflowX: "hidden",
            }}
          >
            <div className="close-button">
              <h3>Player Registration</h3>
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg "
                onClick={onClose}
              >
                <path
                  d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
                  fill="#1B1B1B"
                />
                <path
                  d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
                  fill="#1B1B1B"
                />
                <path
                  d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
                  fill="#1B1B1B"
                />
              </svg>
            </div>

            <form
              onSubmit={handleSubmit}
              className="row"
              style={{ marginRight: "10px", marginLeft: "1px" }}
            >
              <div className="col-md-6 input_group">
                <label htmlFor="FirstName">
                  First Name <span className="edd-required-indicator">*</span>
                </label>

                <input
                  name="FirstName"
                  value={formData.FirstName}
                  onChange={handleChange}
                  id="FirstName"
                  className="input"
                  placeholder="First Name"
                  type="text"
                />
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="LastName">
                  Last Name <span className="edd-required-indicator">*</span>
                </label>
                <input
                  name="LastName"
                  id="LastName"
                  value={formData.LastName}
                  onChange={handleChange}
                  className="input"
                  placeholder="Last Name"
                  type="text"
                />
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="EmailId">
                  Email ID <span className="edd-required-indicator">*</span>
                </label>
                <input
                  name="EmailId"
                  id="EmailId"
                  value={formData.EmailId}
                  onChange={handleChange}
                  className="input"
                  placeholder="Email ID"
                  type="text"
                />
              </div>

              <div className="col-md-6 input_group">
                <label htmlFor="Password">
                  Password <span className="edd-required-indicator">*</span>
                </label>
                <input
                  name="Password"
                  id="Password"
                  value={formData.Password}
                  onChange={handleChange}
                  className="input"
                  placeholder="Password"
                  type="text"
                />
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="PlayerNumber">
                  Jersy # <span className="edd-required-indicator">*</span>
                </label>
                <input
                  name="PlayerNumber"
                  id="PlayerNumber"
                  value={formData.PlayerNumber}
                  onChange={handleChange}
                  className="input"
                  placeholder="Jersy #"
                  type="text"
                />
              </div>
              <div
                className="col-md-6 input_group"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  htmlFor="isAvailable"
                  style={{ marginRight: "10px", marginTop: "5px" }}
                >
                  Check box to be added in Available Player list
                </label>
                <input
                  type="checkbox"
                  id="isAvailable"
                  name="isAvailable"
                  checked={formData.isAvailable}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-12 input_group">
                <label htmlFor="edd_user_login">
                  Select Team
                  <span className="edd-required-indicator">*</span>
                </label>
                <div>
                  <select
                    id="edd_user_login"
                    className="input input_select"
                    style={{ width: "100%" }}
                    disabled={teamListData.length === 0}
                    onChange={handleSelectTeamChange}
                  >
                    <option>Choose Team From Drop Down</option>
                    <option>Register Yourself as A Free Agent</option>
                    {teamListData?.map((team, index) => (
                      <option key={index} value={team.TeamName}>
                        {team.TeamName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-12 input_group">
                <label htmlFor="State">Location</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <select
                    id="State"
                    name="State"
                    className="input input_select"
                    value={formData.State}
                    onChange={handleChange}
                    style={{ marginTop: "13px" }}
                  >
                    <option value="">State</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                  <input
                    style={{
                      marginBottpm: "-20px",
                      marginLeft: "5px",
                    }}
                    name="City"
                    id="City"
                    value={formData.City}
                    onChange={handleChange}
                    className="input"
                    placeholder="City"
                    type="text"
                  />
                </div>
              </div>

              <div className="col-md-12 input_group">
                <label htmlFor="Address">Address</label>
                <textarea
                  value={formData.Address}
                  onChange={handleChange}
                  name="Address"
                  id="Address"
                  className="input"
                  placeholder="Address"
                  type="text"
                />
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="edd_user_pass_confirm">
                  WHAT LEVEL OF PLAY IS THE LEAGUE?
                  <span className="edd-required-indicator">*</span>
                </label>
                <select
                  id="ddlWhatLevelOfPlay"
                  name="PlayLevel"
                  value={formData.PlayLevel || "0"}
                  onChange={handleChange}
                  className="input input_select"
                >
                  <option value="0">Select</option>
                  <option value="1">Level D</option>
                  <option value="2">Level C</option>
                  <option value="3">Level B</option>
                  <option value="4">Level A</option>
                  <option value="5">MAJOR</option>
                </select>
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="DOB">Date Of Birth?</label>
                <input
                  type="date"
                  id="DOB"
                  name="DOB"
                  value={formData.DOB}
                  onChange={handleDOBChange}
                  className="input input_select"
                />
              </div>
              <div
                className="col-md-6 input_group"
                id="edd-user-pass-confirm-wrap"
              >
                <label htmlFor="PhoneNumber">Phone Number</label>
                <input
                  value={formData.PhoneNumber}
                  onChange={handleChange}
                  name="PhoneNumber"
                  id="PhoneNumber"
                  className="input"
                  placeholder="Phone Number"
                  type="text"
                />
              </div>
              <div
                className="col-md-6 input_group"
                id="edd-user-pass-confirm-wrap"
              >
                <label htmlFor="Bats">Bats</label>
                <select
                  id="Bats"
                  value={formData.Bats}
                  onChange={handleChange}
                  className="input input_select"
                >
                  <option value="Right">Right</option>
                  <option value="Left">Left</option>
                  <option value="Both">Both</option>
                </select>
              </div>
              <div
                className="col-md-6 input_group"
                id="edd-user-pass-confirm-wrap"
              >
                <label htmlFor="Throws">Throws</label>
                <select
                  id="Throws"
                  value={formData.Throws}
                  onChange={handleChange}
                  className="input input_select"
                >
                  <option value="Right">Right</option>
                  <option value="Left">Left</option>
                  <option value="Both">Both</option>
                </select>
              </div>
              <div
                className="col-md-6 input_group"
                id="edd-user-pass-confirm-wrap"
              >
                <label htmlFor="Position">Field Position</label>
                <select
                  id="Position"
                  value={formData.Position}
                  onChange={handleChange}
                  className="input input_select"
                >
                  <option value="Out">Out</option>
                  <option value="P">P</option>
                  <option value="C">C</option>
                  <option value="1B">1B</option>
                  <option value="2B">2B</option>
                  <option value="3B">3B</option>
                  <option value="SS">SS</option>
                  <option value="LF">LF</option>
                  <option value="RF">RF</option>
                  <option value="LC">LC</option>
                  <option value="RC">RC</option>
                </select>
              </div>
              <div
                className="col-md-12 input_group"
                id="edd-user-pass-confirm-wrap"
              >
                <label htmlFor="Comments">
                  Comments/Fielding Position/Level Of Play
                </label>
                <input
                  value={formData.Comments}
                  onChange={handleChange}
                  name="Comments"
                  id="Comments"
                  className="input"
                  placeholder="Comments"
                  type="text"
                ></input>
              </div>
              <div className="col-md-12 input_group">
                <label htmlFor="PlayerPhoto">Player Photo</label>
                <button
                  className="btn btn_primary w-20"
                  onClick={handleButtonClickPlayer}
                >
                  Choose File
                </button>
                <input
                  type="file"
                  ref={fileInputRefPlayer}
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                {(formData.PlayerPhoto || imageName) && (
                  <img
                    src={
                      formData?.PlayerPhoto
                        ? `${BASE_URL}/api/v1/files/PlayerPhoto/${formData.PlayerPhoto}`
                        : imageName
                    }
                    alt="Player Image"
                    style={{ maxWidth: "150px" }}
                  />
                )}
              </div>
              <div className="col-md-12 input_group">
                <label htmlFor="License">License Image</label>
                <button
                  className="btn btn_primary w-20"
                  onClick={handleButtonClick}
                >
                  Choose File
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleLicenseImageChange}
                />
                {(formData.License || imageName) && (
                  <img
                    src={
                      formData?.License
                        ? `${BASE_URL}/api/v1/files/LicenseImage/${formData.License}`
                        : imageName
                    }
                    alt="License Image"
                    style={{ maxWidth: "150px" }}
                  />
                )}
              </div>
              <div className="col-md-12 btn_wrap">
                <button
                  type="submit"
                  className="btn btn_primary w-100"
                  disabled={isRegisterDisabled}
                >
                  {isRegisterDisabled
                    ? "Wait to Upload the image"
                    : "Register Now"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { PlayerReg };

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { API_URLS, Globals } from "../../../Utils";
import he from "he";

const ProAmRules = () => {
  const [tourDetail, setTourDetail] = useState("");
  const params = useParams();
  const { ParkId, TourLeagueName } = params;
  const teamId = window.sessionStorage.getItem("TeamId");
  useEffect(() => {
    const getProAmByParkId = async (ParkId) => {
      try {
        const response = await axios.get(
          `${API_URLS.GET_PROAM_BY_ParkId}?selectedParkId=${ParkId}`,
          {
            selectedParkId: ParkId,
          }
        );
        const divisionHtml = response.data.data.result[0].Division;
        setTourDetail(he.decode(divisionHtml));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getProAmByParkId(ParkId);
  }, [ParkId]);

  // Set PayPal sandbox environment URLs and business email for testing

  const buildPaymentLink = () => {
    const baseAmount = Number(ParkId || 0) === 233 ? 450.0 : 300.0;
    const processingFeeRate = 3.5 / 100;
    const processingFee = baseAmount * processingFeeRate + 0.49; //Adding Fixed Fee
    const totalAmount = (baseAmount + processingFee).toFixed(2);

    const amount = `amount=${totalAmount}`;
    const product = `item_name=${
      new URLSearchParams(window.location.search).get("TourName") || "Unknown"
    } (Base Fee: $${baseAmount}, Processing Fee: $${processingFee.toFixed(2)})`;
    const quantity = "quantity=1";

    // let paymentLink = `${Globals.postUrl}${Globals.cmdSinglePayment}${Globals.urlVarSeperator}business=${Globals.business}${Globals.urlVarSeperator}${product}${Globals.urlVarSeperator}${amount}${Globals.urlVarSeperator}${quantity}${Globals.urlVarSeperator}`;
    // paymentLink += `return=${Globals.returnUrl}${Globals.urlVarSeperator}`;
    // paymentLink += `cancel_return=${Globals.cancelReturnUrl}`;
    let paymentLink = `${Globals.postUrl}${Globals.cmdSinglePayment}${Globals.urlVarSeperator}business=${Globals.business}${Globals.urlVarSeperator}${product}${Globals.urlVarSeperator}${amount}${Globals.urlVarSeperator}${quantity}${Globals.urlVarSeperator}`;

    // Append ParkId to the return URL
    paymentLink += `return=${encodeURIComponent(
      `${Globals.returnUrl}?ParkId=${ParkId}`
    )}${Globals.urlVarSeperator}`;

    // Append the cancel_return URL
    paymentLink += `cancel_return=${Globals.cancelReturnUrl}`;

    return paymentLink;
  };

  const handlePaymentClick = () => {
    const paymentLink = buildPaymentLink();
    // console.log("Link", paymentLink);
    window.location.href = paymentLink; // Redirect to PayPal for payment
  };

  return (
    <div className="App">
      <div className="site-content">
        <div style={{ width: "100%", textAlign: "center", fontSize: "medium" }}>
          <b>
            <label id="lblTitle">
              Thank you for registering: {TourLeagueName}
            </label>
          </b>

          <div>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr style={{ textAlign: "center" }}>
                  <td style={{ height: "20px", fontSize: "medium" }}>&nbsp;</td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td style={{ height: "20px", fontSize: "medium" }}>
                    <b>
                      When editing in your Players on your Player management
                      section, be sure to select the correct Level of Play they
                      are capable of playing at: A - B - C - D
                    </b>
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td style={{ height: "20px" }}>&nbsp;</td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td style={{ height: "20px", textDecoration: "underline" }}>
                    {ParkId == 233 ? (
                      <b>Entry Fee is $450.00 + Tax</b>
                    ) : (
                      <b>Entry Fee is $300.00 + Tax</b>
                    )}
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td style={{ height: "20px", fontSize: "medium" }}>
                    <b>
                      You can use your Debit or Credit Card Payable through our:
                    </b>
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <button
                      id="imgBtnPay"
                      imageUrl="~/images/PayButton.png"
                      width="200px"
                      height="50px"
                      onClick={handlePaymentClick}
                      className="btn btn_primary w-50"
                    >
                      PayPal
                    </button>
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td style={{ height: "20px", fontSize: "medium" }}>
                    <b>OR</b>
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td style={{ height: "20px", fontSize: "medium" }}>
                    <b>MAKE CHECK OUT TO: ProAm Softball Inc.</b>
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td style={{ height: "20px", fontSize: "medium" }}>
                    <b>MAIL TO: ProAm Softball Inc</b>
                    <br />
                    PO BOX 3377
                    <br /> Pinellas Park FL 33780
                    <br /> PUT TEAM NAME ON CHECK <br />
                  </td>
                </tr>
                <tr style={{ textAlign: "center" }}>
                  <td style={{ height: "20px", fontSize: "medium" }}>&nbsp;</td>
                </tr>
              </tbody>
            </table>
            <div
              dangerouslySetInnerHTML={{
                __html: tourDetail,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProAmRules;

import React, { useState, useEffect } from "react";
import { Link, useLocation, Navigate } from "react-router-dom";
import constants from "../../../../utils/constants";
import AOS from "aos";
import "aos/dist/aos.css";
import "./AdminNavbar.css";
import { PlayerProf } from "../../UpdatePlayerProfile/PlayerProf";
import AnnualFees from "./Components/AnnualFees";
import axios from "axios";
import { API_URLS } from "../../../../Utils";
import EditTeamForCoach from "./Components/EditTeamForCoach";

const AdminNavbar = () => {
  const location = useLocation();
  const userRole = window.sessionStorage.getItem("UserType");
  const isCoach = userRole === constants.scopes.COACH;
  const isSuperAdmin = userRole === constants.scopes.SUPERADMIN;
  const TeamIDfromCoach = window.sessionStorage.getItem("TeamId");
  const isPlayer = userRole === constants.scopes.PLAYER;
  const PlayerId = window.sessionStorage.getItem("PlayerId");
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [loggedOut, setLoggedOut] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showPlayerProf, setshowPlayerProf] = useState(false);
  const [showTeamProf, setshowTeamProf] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [annualFees, setAnnualFees] = useState(true);
  const getTeamByTeamId = async (TeamIDfromCoach) => {
    try {
      const {
        data: { data },
      } = await axios.get(API_URLS.GET_TEAM_BY_ID, {
        params: {
          SelectedTeamId: TeamIDfromCoach,
        },
      });
      // console.log("team here", data.result[0]);
      setAnnualFees(data.result[0].AunnualFees);
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
  const handleNavLinkClick = (path) => {
    setActiveLink(path);
  };
  const openPlayerProfModal = () => {
    setshowPlayerProf(true);
  };

  const closePlayerProfModal = () => {
    setshowPlayerProf(false);
  };

  const openTeamProfModal = () => {
    setshowTeamProf(true);
  };

  const closeTeamProfModal = () => {
    setshowTeamProf(false);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (isCoach) {
      getTeamByTeamId(TeamIDfromCoach);
    }
  }, [isCoach]);
  useEffect(() => {
    if (
      isCoach &&
      (annualFees === false || annualFees === null || annualFees === undefined)
    ) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [annualFees, isCoach]);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    AOS.init({
      duration: 1200,
      offset: 100,
      delay: 0,
      once: true,
      anchorPlacement: "top-bottom",
    });

    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        headerJs();
      }
    };

    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".header nav") &&
        !event.target.closest(".btn_navbar")
      ) {
        headerJs();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleNavbar = () => {
    document.querySelector(".header nav").classList.toggle("open");
    document.body.classList.toggle("scroll_off");
    document.querySelector(".btn_navbar").classList.toggle("open");
  };
  const closeNavbar = () => {
    document.querySelector(".header nav").classList.remove("open");
    document.body.classList.remove("scroll_off");
    document.querySelector(".btn_navbar").classList.remove("open");
  };
  const headerJs = () => {
    document.querySelector(".header nav").classList.remove("open");
    document.body.classList.remove("scroll_off");
    document.querySelector(".btn_navbar").classList.remove("open");
  };

  const logout = () => {
    window.sessionStorage.clear();
    setLoggedOut(true);
  };

  if (loggedOut) {
    return <Navigate to="/" />;
  }

  return (
    <section className="hero_banner" style={{ marginBottom: "50px" }}>
      <header className="header" data-aos="fade" role="banner">
        <div className="container header-area" style={{ maxWidth: "1440px" }}>
          <div className="sm_sc">
            <a href="https://www.proam50softball.com/" className="logo">
              <img src="images/logo.png" alt="ProAm50 Logo" />
            </a>
            <button className="btn_navbar" onClick={toggleNavbar}>
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="30"></circle>
                <path
                  className="line--1"
                  d="M0 40h62c13 0 6 28-4 18L35 35"
                ></path>
                <path className="line--2" d="M0 50h70"></path>
                <path
                  className="line--3"
                  d="M0 60h62c13 0 6-28-4-18L35 65"
                ></path>
              </svg>
            </button>
          </div>
          <div className="site-menu">
            <nav
              id="site-navigation"
              className="main-navigation"
              role="navigation"
            >
              <div className="menu-primary-menu-container">
                <ul
                  id="menu-primary-menu"
                  className="menu nav-menu"
                  aria-expanded="false"
                >
                  <li
                    className={`menu-item menu-item-type-post_type menu-item-object-page ${
                      activeLink === "/admin" ? "active" : ""
                    }`}
                  >
                    <Link
                      to="/admin"
                      onClick={() => {
                        setActiveLink("/admin");
                        closeNavbar();
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  {isSuperAdmin && (
                    <>
                      <li
                        className={`menu-item menu-item-type-post_type menu-item-object-sp_team ${
                          activeLink === "/admin/manage-scorekeepers"
                            ? "active"
                            : ""
                        }`}
                        style={{ paddingLeft: "50px" }}
                      >
                        <Link
                          to="/admin/manage-scorekeepers"
                          onClick={() => {
                            setActiveLink("/admin/manage-scorekeepers");
                            closeNavbar();
                          }}
                        >
                          Manage Scorekeepers
                        </Link>
                      </li>

                      <li
                        className={`menu-item menu-item-type-post_type menu-item-object-sp_team ${
                          activeLink === "/admin/manage-50-tournaments"
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/admin/manage-50-tournaments"
                          onClick={() => {
                            setActiveLink("/admin/manage-50-tournaments");
                            closeNavbar();
                          }}
                        >
                          Manage Tournaments
                        </Link>
                      </li>

                      <li
                        className={`menu-item menu-item-type-post_type menu-item-object-sp_team ${
                          activeLink === "/admin/manage-50-teams"
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link
                          to="/admin/manage-50-teams"
                          onClick={() => {
                            setActiveLink("/admin/manage-50-teams");
                            closeNavbar();
                          }}
                        >
                          Manage Teams
                        </Link>
                      </li>
                      <li className="logo">
                        {/* <a href="#">
                          <img src={logo} alt="ProAm50 Logo" className="logo" />
                        </a> */}
                        <Link style={{ paddingRight: "30px" }}>
                          <img
                            src="/images/logo.png"
                            alt="ProAm50 Logo"
                            className="logo"
                          />
                        </Link>
                      </li>
                      <li
                        className={`menu-item menu-item-type-post_type menu-item-object-sp_team ${
                          activeLink === "/admin/all-players" ? "active" : ""
                        }`}
                      >
                        <Link
                          to="/admin/all-players"
                          onClick={() => {
                            handleNavLinkClick("/admin/all-players");
                            closeNavbar();
                          }}
                        >
                          Delete Extra Player
                        </Link>
                      </li>

                      <li
                        className={`menu-item menu-item-type-post_type menu-item-object-page ${
                          activeLink === "/admin/manage-umpire" ? "active" : ""
                        }`}
                      >
                        <Link
                          to="/admin/manage-umpire"
                          onClick={() => {
                            handleNavLinkClick("/admin/manage-umpire");
                            closeNavbar();
                          }}
                        >
                          Manage Umpire
                        </Link>
                      </li>

                      <li
                        className="menu-item menu-item-has-children"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <a style={{ paddingRight: "50px", cursor: "pointer" }}>
                          {" "}
                          Others
                        </a>

                        {dropdownVisible && (
                          <ul className="sub-menu">
                            <li
                              className={` ${
                                activeLink === "/admin/schedule" ? "active" : ""
                              }`}
                            >
                              <Link
                                to="/admin/schedule"
                                onClick={() => {
                                  handleNavLinkClick("/admin/schedule");
                                  closeNavbar();
                                }}
                              >
                                Schedule & Standings
                              </Link>
                            </li>
                            <li
                              className={` ${
                                activeLink === "/admin/hall_of_fame"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <Link
                                to="/admin/hall_of_fame"
                                onClick={() => {
                                  handleNavLinkClick("/admin/hall_of_fame");
                                  closeNavbar();
                                }}
                              >
                                Hall Of Fame
                              </Link>
                            </li>
                            <li
                              className={` ${
                                activeLink === "/admin/manage-brackets"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <Link
                                to="/admin/manage-brackets"
                                onClick={() => {
                                  handleNavLinkClick("/admin/manage-brackets");
                                  closeNavbar();
                                }}
                              >
                                Manage Brackets
                              </Link>
                            </li>
                            <li
                              className={`${
                                activeLink === "/admin/Remembering-Legends"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <Link
                                to="/admin/Remembering-Legends"
                                onClick={() => {
                                  handleNavLinkClick(
                                    "/admin/Remembering-Legends"
                                  );
                                  closeNavbar();
                                }}
                              >
                                Remembering Legends
                              </Link>
                            </li>
                            <li
                              className={`${
                                activeLink === "/admin/Announcements"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <Link
                                to="/admin/Announcements"
                                onClick={() => {
                                  handleNavLinkClick("/admin/Announcements");
                                  closeNavbar();
                                }}
                              >
                                Announcements
                              </Link>
                            </li>
                          </ul>
                        )}
                      </li>
                    </>
                  )}
                  {isCoach && (
                    <>
                      {showModal && (
                        <AnnualFees
                          isOpen={showModal}
                          onClose={handleCloseModal}
                          ParkId={TeamIDfromCoach}
                        />
                      )}
                      {/* EditTeamModel */}
                      <li
                        className={`menu-item menu-item-type-post_type menu-item-object-sp_team ${
                          activeLink ===
                          `/admin/manage-50_team/${TeamIDfromCoach}`
                            ? "active"
                            : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <a onClick={openTeamProfModal}>Edit Your Team</a>
                        {showTeamProf && (
                          <EditTeamForCoach
                            isOpen={showTeamProf}
                            onClose={closeTeamProfModal}
                            SelectedTeamId={TeamIDfromCoach}
                          />
                        )}
                      </li>
                      <li
                        className={`menu-item menu-item-type-post_type menu-item-object-sp_team ${
                          activeLink ===
                          `/admin/manage-50_team_players/${TeamIDfromCoach}`
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link
                          to={`/admin/manage-50_team_players/${TeamIDfromCoach}`}
                          onClick={() => {
                            handleNavLinkClick(
                              `/admin/manage-50_team_players/${TeamIDfromCoach}`
                            );
                            closeNavbar();
                          }}
                        >
                          Manage Players
                        </Link>
                      </li>

                      <li
                        className={`menu-item menu-item-type-post_type menu-item-object-sp_team ${
                          activeLink ===
                          `/admin/upcoming_Tournament/${TeamIDfromCoach}`
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link
                          to={`/admin/upcoming_Tournament/${TeamIDfromCoach}`}
                          onClick={() => {
                            handleNavLinkClick(
                              `/admin/upcoming_Tournament/${TeamIDfromCoach}`
                            );
                            closeNavbar();
                          }}
                        >
                          ProAm Tournaments
                        </Link>
                      </li>
                      <li className="logo">
                        <a href="#">
                          <img
                            src="images/logo.png"
                            alt="ProAm50 Logo"
                            className="logo"
                          />
                        </a>
                      </li>
                      <li
                        className={`menu-item menu-item-type-post_type menu-item-object-sp_team ${
                          activeLink ===
                          `/admin/stats_&_schedule/${TeamIDfromCoach}`
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link
                          to={`/admin/stats_&_schedule/${TeamIDfromCoach}`}
                          onClick={() => {
                            handleNavLinkClick(
                              `/admin/stats_&_schedule/${TeamIDfromCoach}`
                            );
                            closeNavbar();
                          }}
                        >
                          Schedule
                        </Link>
                      </li>
                      <li
                        className={`menu-item menu-item-type-post_type menu-item-object-sp_team ${
                          activeLink ===
                          `/admin/manage_All_players_coach/${TeamIDfromCoach}`
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link
                          to={`/admin/manage_All_players_coach/${TeamIDfromCoach}`}
                          onClick={() => {
                            handleNavLinkClick(
                              `/admin/manage_All_players_coach/${TeamIDfromCoach}`
                            );
                            closeNavbar();
                          }}
                        >
                          All Players
                        </Link>
                      </li>
                      <li
                        className={`menu-item menu-item-type-post_type menu-item-object-sp_team ${
                          activeLink ===
                          `/admin/available_Player/${TeamIDfromCoach}`
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link
                          to={`/admin/available_Player/${TeamIDfromCoach}`}
                          onClick={() => {
                            handleNavLinkClick(
                              `/admin/available_Player/${TeamIDfromCoach}`
                            );
                            closeNavbar();
                          }}
                        >
                          Avaialble Player
                        </Link>
                      </li>
                    </>
                  )}
                  {isPlayer && (
                    <>
                      <li
                        className={`menu-item menu-item-type-post_type menu-item-object-sp_team ${
                          activeLink === `/admin/stats_&_schedule/${PlayerId}`
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link
                          to={`/admin/stats_&_schedule/${PlayerId}`}
                          onClick={() => {
                            handleNavLinkClick(
                              `/admin/stats_&_schedule/${PlayerId}`
                            );
                            closeNavbar();
                          }}
                        >
                          Schedule
                        </Link>
                      </li>
                      <li
                        className={`menu-item menu-item-type-post_type menu-item-object-sp_team ${
                          activeLink ===
                          `/admin/manage-50_team_players/${PlayerId}`
                            ? "active"
                            : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <a onClick={openPlayerProfModal}>Edit Player Profile</a>
                        {showPlayerProf && (
                          <PlayerProf
                            isOpen={showPlayerProf}
                            onClose={closePlayerProfModal}
                          />
                        )}
                      </li>
                      <li className="logo">
                        <a href="#">
                          <img
                            src="images/logo.png"
                            alt="ProAm50 Logo"
                            className="logo"
                          />
                        </a>
                      </li>

                      <li
                        className={`menu-item menu-item-type-post_type menu-item-object-sp_team ${
                          activeLink ===
                          `/admin/upcoming_Tournament/${PlayerId}`
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link
                          to={`/admin/upcoming_Tournament/${PlayerId}`}
                          onClick={() => {
                            handleNavLinkClick(
                              `/admin/upcoming_Tournament/${PlayerId}`
                            );
                            closeNavbar();
                          }}
                        >
                          ProAm Tournaments
                        </Link>
                      </li>
                    </>
                  )}
                  <li className="menu-item">
                    <a href="javascript:void(0);" onClick={logout}>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </section>
  );
};

export { AdminNavbar };

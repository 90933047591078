import React, { useState, useRef, useEffect } from "react";

import Modal from "react-modal";
import swal from "sweetalert";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { showAlert } from "../../../../../components/SwalAlert/showAlert";
import axios from "axios";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { API_URLS, BASE_URL } from "../../../../../Utils";

Modal.setAppElement("#root");

const formatDate = (dateString) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

const TeamReg = ({ isOpen, onClose, parkId }) => {
  //console.log(parkId);
  const [bracketWinner, setBracketWinner] = useState([]);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [selectedYear, setSelectedYear] = useState("2025");
  const [selectedTeamRegTournament, setSelectedTeamRegTournament] = useState();
  const [txtTeamName, setTxtTeamName] = useState("");
  const [txtCoachName, setTxtCoachName] = useState("");
  const [txtCoachEmail, setTxtCoachEmail] = useState("");
  const [txtCoachPassword, setTxtCoachPassword] = useState("");
  const [ddlTeamLevel, setDdlTeamLevel] = useState("0");
  const [txtPrevPark, setTxtPrevPark] = useState("");
  const [imageName, setImageName] = useState("");
  const [ddlWhatLevelOfPlay, setDdlWhatLevelOfPlay] = useState("0");
  const [txtPhoneNumber, setTxtPhoneNumber] = useState("");
  const [txtAddress, setTxtAddress] = useState("");
  const [tournamentList, setTournamentList] = useState([]);
  const [isproam, setIsproam] = useState(true);
  const [teamsPhoto, setTeamsPhoto] = useState("");
  const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);
  const [ageLevel, setAgelevel] = useState("");
  const [formData, setFormData] = useState({
    TeamPhoto: teamsPhoto,
  });
  const [selectedTournamentName, setSelectedTournamentName] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const fileInputRef = useRef(null);
  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  const TeamRegistration = async (event) => {
    event.preventDefault();
    if (!selectedTeamRegTournament) {
      showAlert("Info", "Please select Tournament from above..", "info");

      return;
    }

    if (!ageLevel) {
      showAlert("Info", "Please Select Age Level.", "info");
      return;
    }

    const teamName = txtTeamName.trim();
    if (!teamName) {
      showAlert("Info", "Please enter Team Name.", "info");
      return;
    }
    const coachName = txtCoachName.trim();
    if (!coachName) {
      showAlert("Info", "Please enter Coach Name.", "info");
      return;
    }

    const coachEmail = txtCoachEmail.trim();
    if (!coachEmail) {
      showAlert("Info", "Please enter Coach Email.", "info");
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(coachEmail.trim())) {
      showAlert("Info", "Please Enter a valid Email ID.", "info");
      return;
    }
    try {
      // Append the email as a query parameter
      const emailCheckUrl = `${
        API_URLS.CHECK_COACH_EXIST
      }?Email=${encodeURIComponent(coachEmail)}`;

      const emailCheckResponse = await fetch(emailCheckUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!emailCheckResponse.ok) {
        throw new Error("Email check failed");
      }

      const emailCheckResult = await emailCheckResponse.json();
      if (
        emailCheckResult.data.result.length > 0 &&
        Object.values(emailCheckResult.data.result[0])[0]
      ) {
        showAlert(
          "warning",
          "This COACH E-Mail already exists. Please use a different E-Mail .",
          "warning"
        );
        return;
      }
    } catch (error) {
      showAlert("Error checking email", error.message, "error");
      return;
    }
    const coachPassword = txtCoachPassword.trim();
    if (!coachPassword) {
      showAlert("Info", "Please enter Password.", "info");
      return;
    }
    const TeamLevel = parseInt(ddlTeamLevel);
    if (TeamLevel === 0) {
      showAlert("Info", "Please select Team Level.", "info");
      return;
    }

    // const PlayingLeaguePark = txtPrevPark.trim();
    // if (!PlayingLeaguePark) {
    //   showAlert(
    //     "Info",
    //     "Please enter the Name of the Park your team plays at.",
    //     "info"
    //   );
    //   return;
    // }

    // const LeagueLevel = parseInt(ddlWhatLevelOfPlay);
    // if (LeagueLevel === 0) {
    //   showAlert(
    //     "Info",
    //     "Please select the Level of Play for the League.",
    //     "info"
    //   );
    //   return;
    // }
    const PhoneNum = txtPhoneNumber.trim();
    if (!PhoneNum) {
      showAlert("Info", "Please enter Phone Number.", "info");
      return;
    }

    const Address = txtAddress.trim();
    if (!Address) {
      showAlert("Info", "Please enter Address.", "info");
      return;
    }

    const param = {
      Season: selectedTournamentName,
      TeamName: teamName,
      PlayingField: "1",
      NoOfGames: 20,
      Type: "Tournament",
      IsProAm: isproam,
      CoachName: coachName,
      CoachEmail: coachEmail,
      CoachPassword: coachPassword,
      ParkId: selectedTeamRegTournament,
      SelectedDay: "Monday",
      TeamLevel: TeamLevel,
      PhoneNumber: PhoneNum,
      Address: Address,
      PlayingLeaguePark: "Null",
      LeagueLevel: 0,
      TeamPhoto: teamsPhoto,
      AgeLevel: ageLevel,
    };

    try {
      //console.log("tour name", param);
      // return;
      const response = await fetch(API_URLS.ADD_TEAM, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(param),
      });

      if (response.ok) {
        showAlert("Success", "Team Added Successfully", "success");

        const emailParams = {
          Email: coachEmail,
          TeamName: teamName,
          Season: selectedTournamentName,
        };

        try {
          const emailResponse = await fetch(API_URLS.ADD_TEAM_SEND_EMAIL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(emailParams),
          });
          if (emailResponse.ok) {
            console.log("Email sent successfully");
          } else {
            console.log("Error sending email");
          }
        } catch (error) {
          console.error("Error sending email:", error);
        }
      } else {
        throw new Error("Error adding team");
      }
    } catch (error) {
      showAlert("Error adding team", error.message, "error");
    }
  };
  const handleImageChange = async (e) => {
    setIsRegisterDisabled(true);
    setTimeout(() => {
      setIsRegisterDisabled(false);
    }, 5000);
    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        let data = new FormData();
        data.append("teamImage", file);
        const response = await axios.post(API_URLS.UPLOAD_TEAM_IMAGE, data);

        if (response.data.data.image.filename) {
          setFormData((prevState) => ({
            ...prevState,
            TeamPhoto: response.data.data.image.filename,
          }));
          setTeamsPhoto(response.data.data.image.filename);
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const fnTournamentList = () => {
    const url = `${BASE_URL}/api/v1/web/park/get_all_proam_tournaments_by_year?year=${selectedYear}`;
    return fetch(url) // Add `return` to return the Promise
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        setTournamentList(data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const fetchLatestBracketWinner = async () => {
    try {
      const response = await axios.get(API_URLS.Get_Latest_BracketWinners);
      setBracketWinner(response.data.data.result);

      //console.log("bracket winner Data", response.data.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchLatestBracketWinner();
  }, []);

  useEffect(() => {
    setIsFetching(true);
    fnTournamentList()
      .then(() => {
        setTimeout(() => {
          setIsFetching(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Error fetching tournaments:", error);
        setIsFetching(false);
      });
  }, [selectedYear]);
  useEffect(() => {
    if (parkId) {
      setSelectedTeamRegTournament(parkId);
    }
  }, [parkId]);
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: true,
    fade: true,
  };
  const customStyles = {
    content: {
      width: "80%", // Adjust this value to your desired width
      maxWidth: "1015.2px", // Optional: Set a maximum width
      // Center the modal horizontally
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",

      margin: "auto",

      // Add some padding
      // Add other styles as needed
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Rules Modal"
      className="modal-content player orange-scrollbar"
      overlayClassName="modal-overlay"
      style={{ ...customStyles }}
    >
      <div
        className="registration_modal playerRegistration_modal "
        id="playerRegistration"
      >
        <div className="modal_body">
          <div className="slider_wrap">
            <div className="slick teamReg_slick">
              <Slider {...settings}>
                {bracketWinner.map((bracket, index) => (
                  <div key={index} className="slick_item">
                    <img
                      src={`${BASE_URL}/api/v1/files/BracketPhoto/${bracket.Photo}`}
                      alt={bracket.Detail}
                    />
                    <div className="content">
                      <h3>{bracket.Detail}</h3>
                      <span>{formatDate(bracket.ProAmPlayingDate)}</span>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div
            className="form_wrap teamReg_form orange-scrollbar"
            style={{
              overflowY: "auto",
              maxHeight: "600.2px",
              overflowX: "hidden",
            }}
          >
            <div className="close-button">
              <h3>Team Registration</h3>
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg "
                onClick={onClose}
              >
                <path
                  d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
                  fill="#1B1B1B"
                />
                <path
                  d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
                  fill="#1B1B1B"
                />
                <path
                  d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
                  fill="#1B1B1B"
                />
              </svg>
            </div>
            <form
              onSubmit={TeamRegistration}
              className="row"
              style={{ marginRight: "10px", marginLeft: "1px" }}
            >
              <div className="col-md-6 input_group">
                <label htmlFor="status">Year</label>
                <select
                  value={selectedYear}
                  onChange={(e) => {
                    setSelectedYear(e.target.value);
                    fnTournamentList();
                  }}
                  className="input input_select"
                >
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                </select>
              </div>
              <div className="col-md-12 input_group">
                <label htmlFor="edd_user_login">
                  Select Tournament
                  <span className="edd-required-indicator">*</span>
                </label>
                {parkId ? (
                  <div className="input">
                    {tournamentList.data?.result.find(
                      (tournament) => tournament.ParkId === parkId
                    )?.FirstName || "Tournament not found"}
                  </div>
                ) : (
                  <select
                    id="ddlTour"
                    value={selectedTeamRegTournament}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      const selectedTournament =
                        tournamentList.data?.result.find(
                          (tournament) =>
                            tournament.ParkId === parseInt(e.target.value)
                        );

                      setSelectedTeamRegTournament(e.target.value); // This stores the ParkId
                      setSelectedTournamentName(
                        selectedTournament?.FirstName || ""
                      );
                    }}
                    className="input input_select"
                    disabled={isFetching}
                  >
                    <option>Select Tournament</option>
                    {tournamentList.data?.result.map((tournament) => (
                      <option key={tournament.ParkId} value={tournament.ParkId}>
                        {tournament.FirstName}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="edd_user_pass">
                  Team Name <span className="edd-required-indicator">*</span>
                </label>
                <input
                  name="txtTeamName"
                  id="txtTeamName"
                  className="input"
                  placeholder="Team Name"
                  type="text"
                  value={txtTeamName}
                  onChange={(e) => setTxtTeamName(e.target.value)}
                />
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="edd_user_pass_confirm">
                  Coach Name<span className="edd-required-indicator">*</span>
                </label>
                <input
                  name="txtCoachName"
                  id="txtCoachName"
                  className="input"
                  placeholder="Coach Name"
                  type="text"
                  value={txtCoachName}
                  onChange={(e) => setTxtCoachName(e.target.value)}
                />
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="edd_user_pass_confirm">
                  Coach Email<span className="edd-required-indicator">*</span>
                </label>
                <input
                  name="txtCoachEmail"
                  id="txtCoachEmail"
                  className="input"
                  placeholder="Coach Name"
                  type="text"
                  value={txtCoachEmail}
                  onChange={(e) => setTxtCoachEmail(e.target.value)}
                />
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="edd_user_pass_confirm">
                  Coach Password
                  <span className="edd-required-indicator">*</span>
                </label>
                <input
                  name="txtCoachPassword"
                  id="txtCoachPassword"
                  className="input"
                  placeholder="Coach Password"
                  type="text"
                  value={txtCoachPassword}
                  onChange={(e) => setTxtCoachPassword(e.target.value)}
                />
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="edd_user_pass_confirm">
                  Team Level<span className="edd-required-indicator">*</span>
                </label>
                <select
                  id="ddlTeamLevel"
                  value={ddlTeamLevel}
                  onChange={(e) => setDdlTeamLevel(e.target.value)}
                  className="input input_select"
                >
                  <option selected="selected" value="0">
                    Select
                  </option>
                  <option value="1">Level D</option>
                  <option value="2">Level C</option>
                  <option value="3">Level B</option>
                  <option value="4">Level A</option>
                  <option value="5">MAJOR</option>
                </select>
              </div>
              {/* <div className="col-md-12 input_group">
                <label htmlFor="edd_user_pass_confirm">
                  NAME OF PARK YOUR TEAM PLAYS LEAGUE AT?
                  <span className="edd-required-indicator">*</span>
                </label>
                <input
                  name="txtPrevPark"
                  id="txtPrevPark"
                  className="input"
                  placeholder="NAME OF PARK YOUR TEAM PLAYS LEAGUE AT?"
                  type="text"
                  value={txtPrevPark}
                  onChange={(e) => setTxtPrevPark(e.target.value)}
                />
              </div>
              <div className="col-md-12 input_group">
                <label htmlFor="edd_user_pass_confirm">
                  WHAT LEVEL OF PLAY IS THE LEAGUE?
                  <span className="edd-required-indicator">*</span>
                </label>
                <select
                  id="ddlWhatLevelOfPlay"
                  value={ddlWhatLevelOfPlay}
                  onChange={(e) => setDdlWhatLevelOfPlay(e.target.value)}
                  className="input input_select"
                >
                  <option selected="selected" value="0">
                    Select
                  </option>
                  <option value="1">Level D</option>
                  <option value="2">Level C</option>
                  <option value="3">Level B</option>
                  <option value="4">Level A</option>
                  <option value="5">MAJOR</option>
                </select>
              </div> */}
              <div className="col-md-12 input_group">
                <label htmlFor="ageLevel">
                  Age Level
                  <span className="edd-required-indicator">*</span>
                </label>
                <div id="ageLevel" className="radio-group-horizontal">
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="ageLevel"
                      value="40"
                      checked={ageLevel === "40"}
                      onChange={(e) => setAgelevel(e.target.value)}
                    />
                    40s
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="ageLevel"
                      value="50"
                      checked={ageLevel === "50"}
                      onChange={(e) => setAgelevel(e.target.value)}
                    />
                    50s
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="ageLevel"
                      value="60"
                      checked={ageLevel === "60"}
                      onChange={(e) => setAgelevel(e.target.value)}
                    />
                    60s
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="ageLevel"
                      value="65"
                      checked={ageLevel === "65"}
                      onChange={(e) => setAgelevel(e.target.value)}
                    />
                    65s
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="ageLevel"
                      value="70"
                      checked={ageLevel === "70"}
                      onChange={(e) => setAgelevel(e.target.value)}
                    />
                    70s
                  </label>
                  <label className="radio-label">
                    <input
                      type="radio"
                      name="ageLevel"
                      value="75"
                      checked={ageLevel === "75"}
                      onChange={(e) => setAgelevel(e.target.value)}
                    />
                    75s
                  </label>
                </div>
              </div>

              <div className="col-md-6 input_group">
                <label htmlFor="edd_user_pass_confirm">
                  Phone Number
                  <span className="edd-required-indicator">*</span>
                </label>
                <input
                  name="txtPhoneNumber"
                  id="txtPhoneNumber"
                  className="input"
                  placeholder="Phone Number"
                  type="text"
                  value={txtPhoneNumber}
                  onChange={(e) => setTxtPhoneNumber(e.target.value)}
                />
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="edd_user_pass_confirm">
                  Address(City, State)
                  <span className="edd-required-indicator">*</span>
                </label>
                <input
                  name="txtAddress"
                  id="txtAddress"
                  className="input"
                  placeholder="Address(City, State)"
                  type="text"
                  value={txtAddress}
                  onChange={(e) => setTxtAddress(e.target.value)}
                />
              </div>
              <div className="col-md-12 input_group">
                <label htmlFor="TeamPhoto">Team Logo</label>
                <button
                  className="btn btn_primary w-20"
                  onClick={handleButtonClick}
                >
                  Choose File
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                {(formData.TeamPhoto || imageName) && (
                  <img
                    src={
                      formData?.TeamPhoto
                        ? `${BASE_URL}/api/v1/files/TeamPhoto/${formData.TeamPhoto}`
                        : imageName
                    }
                    alt="Player Image"
                    style={{ maxWidth: "150px" }}
                  />
                )}
              </div>

              <div className="col-md-12 btn_wrap">
                <div className="col-md-12 btn_wrap">
                  <button
                    type="submit"
                    className="btn btn_primary w-100"
                    disabled={isRegisterDisabled}
                  >
                    {isRegisterDisabled
                      ? "Wait to Upload the image"
                      : "Register Now"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { TeamReg };

import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_URLS, BASE_URL } from "../../../../Utils";
//import moment from "moment";

const EditPlayerModal = ({ isOpen, onClose, Player, getPlayersHandler }) => {
  const [userinfo, setUserinfo] = useState(null);
  const [id, setId] = useState(userinfo !== null ? userinfo.id : null);
  const [imageName, setImageName] = useState("");
  const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);
  const [isFreeAgent, setIsFreeAgent] = useState(false);
  const [formData, setFormData] = useState({
    ...Player,
    FirstName: "",
    LastName: "",
    EmailId: "",
    Password: "",
    PlayerNumber: "",
    DOB: new Date(),
    State: "",
    Bats: "",
    Throws: "",
    Position: "",
    Address: "",
    City: "",
    PhoneNumber: "",
    Comments: "",
    UniqueNum: "",
    PlayerPhoto: "",
    PlayerStatistics: false,
    License: "",
    PlayLevel: "",
    isAvailable: false,
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (Player) {
      // console.log("playerdata", Player);
      setFormData({
        ...Player,
        FirstName: Player.FirstName || "",
        LastName: Player.LastName || "",
        EmailId: Player.EmailId || "",
        Password: Player.Password || "",
        PlayerNumber: Player.PlayerNumber || "",
        DOB: Player?.DOB ? Player.DOB.split("T")[0] : "",
        State: Player.State || "",
        Bats: Player.Bats || "",
        Throws: Player.Throws || "",
        Position: Player.Position || "",
        Address: Player.Address || "",
        City: Player.City || "",
        PhoneNumber: Player.PhoneNumber || "",
        Comments: Player.Comments || "",
        UniqueNum: Player.UniqueNumber || "",
        PlayerStatistics: Player.PlayerStatistics || false,
        License: Player.License || "",
        PlayeLevel: Player.PlayLevel || "",
        isAvailable: Player.isAvailable || false,
      });
      setImageName(Player.PlayerPhoto || "");
    }
    console.log("player here", Player);
  }, [Player]);

  const handleChange = (e) => {
    const { value, type, checked, id, name } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: name === "PlayLevel" ? parseInt(value, 10) : prevState[name],
      [id]: type === "checkbox" ? checked : value,
    }));

    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked, // Set true if checked, false if unchecked
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      FirstName,
      LastName,
      EmailId,
      Password,
      PlayerNumber,
      UniqueNum,
      PlayLevel,
      isAvailable,
    } = formData;

    if (!FirstName.trim()) {
      alert("Please Enter First Name.", "", "info");
      return;
    }
    if (!LastName.trim()) {
      alert("Please Enter Last Name.", "", "info");
      return;
    }
    if (!EmailId.trim()) {
      alert("Please Enter Email ID.", "", "info");
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(EmailId.trim())) {
      alert("Please Enter a valid Email ID.", "", "info");
      return;
    }
    if (!Password.trim()) {
      alert("Please Enter Password.", "", "info");
      return;
    }
    if (!UniqueNum.trim()) {
      alert("Please Enter UniqueNum.", "", "info");
      return;
    }
    if (!/^\d+$/.test(PlayerNumber)) {
      // Ensure PlayerNumber is numeric
      alert("Please Enter a valid Jersey #.", "", "info");
      return;
    }
    if (PlayLevel === 0 || PlayLevel === null || PlayLevel === "0") {
      alert("Please select the Level of Play for the League.", "", "info");
      return;
    }
    try {
      const response = await axios.patch(API_URLS.UPDATE_Player, formData);

      if (response.status === 200) {
        getPlayersHandler(formData.TeamId);
        onClose();
        alert("Updated Successfully");
      } else {
        alert("Facing Some Error in Updation");
      }
    } catch (error) {
      // console.error("Error:", error);
      alert("An error occurred while processing the request.");
    }
  };

  const handleImageChange = async (e) => {
    setIsRegisterDisabled(true);
    setTimeout(() => {
      setIsRegisterDisabled(false);
    }, 5000);
    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (upload) => {
          setImageName(upload.target.result);
        };
        reader.readAsDataURL(file);
        let data = new FormData();
        data.append("playerImage", file);
        const response = await axios.post(API_URLS.UPLOAD_PLAYER_IMAGE, data);

        if (response.data.data.image.filename) {
          setFormData((prevState) => ({
            ...prevState,
            PlayerPhoto: response.data.data.image.filename,
          }));
        }
      }
    } catch (error) {}
  };
  const handleLicenseImageChange = async (e) => {
    setIsRegisterDisabled(true);
    setTimeout(() => {
      setIsRegisterDisabled(false);
    }, 5000);
    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        let data = new FormData();
        data.append("licenseImage", file);
        const response = await axios.post(API_URLS.UPLOAD_LICENSE_IMAGE, data);

        if (response.data.data.image.filename) {
          setFormData((prevState) => ({
            ...prevState,
            License: response.data.data.image.filename,
          }));
        }
      }
    } catch (error) {
      // console.error("Error uploading license image:", error);
    }
  };
  const customStyles = {
    content: {
      width: "80%", // Adjust this value to your desired width
      // Optional: Set a maximum width
      // Center the modal horizontally
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",
      margin: "auto",

      // Add some padding
      // Add other styles as needed
    },
  };

  const handleDOBChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Edit Player Modal"
      className="modal-content orange-scrollbar"
      overlayClassName="modal-overlay"
      style={{ ...customStyles }}
    >
      <div class="form_wrap teamReg_form">
        <div className="close-button">
          <h3>Edit Player Profile</h3>
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg "
            onClick={onClose}
          >
            <path
              d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
              fill="#1B1B1B"
            />
            <path
              d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
              fill="#1B1B1B"
            />
            <path
              d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
              fill="#1B1B1B"
            />
          </svg>
        </div>
        <form
          onSubmit={handleSubmit}
          className="row"
          style={{ marginRight: "10px", marginLeft: "1px" }}
        >
          <div className="col-md-6 input_group">
            <label htmlFor="FirstName">
              First Name <span className="edd-required-indicator">*</span>
            </label>
            <input
              name="FirstName"
              value={formData.FirstName}
              onChange={handleChange}
              id="FirstName"
              className="input"
              placeholder="First Name"
              type="text"
            />
          </div>

          <div className="col-md-6 input_group">
            <label htmlFor="LastName">
              Last Name <span className="edd-required-indicator">*</span>
            </label>
            <input
              name="LastName"
              id="LastName"
              value={formData.LastName}
              onChange={handleChange}
              className="input"
              placeholder="Last Name"
              type="text"
            />
          </div>

          <div className="col-md-6 input_group">
            <label htmlFor="EmailId">
              Email ID <span className="edd-required-indicator">*</span>
            </label>
            <input
              name="EmailId"
              id="EmailId"
              value={formData.EmailId}
              onChange={handleChange}
              className="input"
              placeholder="Email ID"
              type="email"
            />
          </div>

          <div className="col-md-6 input_group">
            <label htmlFor="Password">
              Password <span className="edd-required-indicator">*</span>
            </label>
            <input
              name="Password"
              id="Password"
              value={formData.Password}
              onChange={handleChange}
              className="input"
              placeholder="Password"
              type="text"
            />
          </div>

          <div className="col-md-6 input_group">
            <label htmlFor="PlayerNumber">
              Jersey # <span className="edd-required-indicator">*</span>
            </label>
            <input
              name="PlayerNumber"
              id="PlayerNumber"
              value={formData.PlayerNumber}
              onChange={handleChange}
              className="input"
              placeholder="Jersey #"
              type="text"
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="DOB">Date Of Birth?</label>
            <input
              type="date"
              id="DOB"
              name="DOB"
              value={formData.DOB}
              onChange={handleDOBChange}
              className="input input_select"
            />
          </div>

          <div className="col-md-6 input_group">
            <label htmlFor="txtPhoto">Photo</label>
            <input
              id="txtPhoto"
              type="file"
              name="txtPhoto"
              onChange={handleImageChange}
              style={{ marginRight: "10px" }}
              className="input"
            />
            {(formData.PlayerPhoto || imageName) && (
              <img
                src={
                  formData?.PlayerPhoto
                    ? `${BASE_URL}/api/v1/files/PlayerPhoto/${formData.PlayerPhoto}`
                    : imageName
                }
                alt="Player"
                style={{ maxWidth: "150px" }}
              />
            )}
          </div>
          <div
            className="col-md-6 input_group"
            style={{ display: "flex", alignItems: "center" }}
          >
            <label
              htmlFor="isAvailable"
              style={{ marginRight: "10px", marginTop: "5px" }}
            >
              Are you available?
            </label>
            <input
              type="checkbox"
              id="isAvailable"
              name="isAvailable"
              checked={formData.isAvailable}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-12 input_group">
            <label htmlFor="License">License Image</label>
            <input
              id="License"
              type="file"
              name="License"
              onChange={handleLicenseImageChange}
              style={{ marginRight: "10px" }}
              className="input"
            />
            {(formData.License || imageName) && (
              <img
                src={
                  formData?.License
                    ? `${BASE_URL}/api/v1/files/LicenseImage/${formData.License}`
                    : `${BASE_URL}/api/v1/files/LicenseImage/${imageName}`
                }
                alt="License Image"
                style={{ maxWidth: "150px" }}
              />
            )}
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="UniqueNum">
              Unique Number <span className="edd-required-indicator">*</span>
            </label>
            <input
              id="UniqueNum"
              value={formData.UniqueNum}
              onChange={handleChange}
              className="input"
              placeholder="Unique Number"
              type="text"
              disabled
            />
          </div>

          <div className="col-md-6 input_group">
            <input
              type="checkbox"
              checked={formData.PlayerStatistics}
              onChange={handleChange}
              id="PlayerStatistics"
            />
            <label htmlFor="PlayerStatistics">Show Stats</label>
          </div>

          {/* <p
              id="edd-user-pass-confirm-wrap"
              className="edd_register_password"
            >
              <input
                type="checkbox"
                checked={isFreeAgent}
                onChange={() => setIsFreeAgent(!isFreeAgent)}
                id="isFreeAgent"
                name="isFreeAgent"
              />
              <label htmlFor="isFreeAgent">Free Agent</label>
            </p> */}

          <div className="col-md-12 input_group">
            <label htmlFor="State">Location</label>
            <select
              id="State"
              name="State"
              style={{ width: "50%", marginBottom: "10px" }}
              value={formData.State}
              onChange={handleChange}
              className="input input_select"
            >
              <option value="">State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
            <input
              style={{ width: "48%", marginTop: "0px", marginLeft: "5px" }}
              name="City"
              id="City"
              value={formData.City}
              onChange={handleChange}
              className="input"
              placeholder="City"
              type="text"
            />
          </div>

          <div className="col-md-6 input_group">
            <label htmlFor="Address">Address</label>
            <textarea
              value={formData.Address}
              onChange={handleChange}
              name="Address"
              id="Address"
              className="input"
              placeholder="Address"
              type="text"
            />
          </div>

          <div className="col-md-6 input_group">
            <label htmlFor="PhoneNumber">Phone Number</label>
            <input
              value={formData.PhoneNumber}
              onChange={handleChange}
              name="PhoneNumber"
              id="PhoneNumber"
              className="input"
              placeholder="Phone Number"
              type="text"
            />
          </div>
          <div className="col-md-12 input_group">
            <label htmlFor="edd_user_pass_confirm">
              WHAT LEVEL OF PLAY IS THE LEAGUE?
              <span className="edd-required-indicator">*</span>
            </label>
            <select
              id="PlayLevel"
              name="PlayLevel"
              value={formData.PlayLevel || "0"}
              onChange={handleChange}
              className="input input_select"
            >
              <option value="0">Select</option>
              <option value="1">Level D</option>
              <option value="2">Level C</option>
              <option value="3">Level B</option>
              <option value="4">Level A</option>
              <option value="5">MAJOR</option>
            </select>
          </div>

          <div className="col-md-6 input_group">
            <label htmlFor="Bats">Bats</label>
            <select
              id="Bats"
              value={formData.Bats}
              onChange={handleChange}
              className="input input_select"
            >
              <option value="Right">Right</option>
              <option value="Left">Left</option>
              <option value="Both">Both</option>
            </select>
          </div>

          <div className="col-md-6 input_group">
            <label htmlFor="Throws">Throws</label>
            <select
              id="Throws"
              value={formData.Throws}
              onChange={handleChange}
              className="input input_select"
            >
              <option value="Right">Right</option>
              <option value="Left">Left</option>
              <option value="Both">Both</option>
            </select>
          </div>

          <div className="col-md-6 input_group">
            <label htmlFor="Position">Field Position</label>
            <select
              id="Position"
              style={{ width: "100%" }}
              value={formData.Position}
              onChange={handleChange}
              name="Position"
              className="input input_select"
            >
              <option value="Out">Out</option>
              <option value="P">P</option>
              <option value="C">C</option>
              <option value="1B">1B</option>
              <option value="2B">2B</option>
              <option value="3B">3B</option>
              <option value="SS">SS</option>
              <option value="LF">LF</option>
              <option value="RF">RF</option>
              <option value="LC">LC</option>
              <option value="RC">RC</option>
            </select>
          </div>

          <div className="col-md-12 input_group">
            <label htmlFor="Comments">
              Comments/Fielding Position/Level Of Play
            </label>
            <textarea
              value={formData.Comments}
              onChange={handleChange}
              name="Comments"
              id="Comments"
              className="input"
              placeholder="Comments"
              type="text"
            ></textarea>
          </div>

          <div className="col-md-12 btn_wrap">
            <button
              type="submit"
              className="btn btn_primary w-100"
              disabled={isRegisterDisabled}
            >
              {isRegisterDisabled
                ? "Wait to Upload the image"
                : "Update Player Profile"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditPlayerModal;

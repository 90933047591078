import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { BASE_URL } from "../../../Utils";

import Edit50Tournaments from "./components/Edit50Tournaments";
import AddProamTournament from "./components/AddProamTournament";

const TournamentTable = ({ onEdit, refreshTournament }) => {
  const [showSchedule, setShowSchedule] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [tournaments, setTournaments] = useState([]);

  const openScheduleModal = () => {
    setShowSchedule(true);
  };
  const closeScheduleModal = () => {
    setShowSchedule(false);
  };
  const fnTournamentList = () => {
    // debugger;
    const url = `${BASE_URL}/api/v1/web/park/get_all_proam_tournaments_by_year?year=${selectedYear}`;
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        setTournaments(data.data.result);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
  useEffect(() => {
    fnTournamentList();
  }, [selectedYear]);
  return (
    <div className="container">
      <div className="tabs_container">
        <div className="tab_content active" data-tab="Schedules">
          <center>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
              }}
            >
              Manage ProAm Tournaments
            </h2>
          </center>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4 style={{ margin: 0 }}>Select Year: </h4>
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                className="selection"
                style={{ marginLeft: "10px" }}
              >
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>
            </div>

            <button
              className="btn btn_primary text_upp"
              onClick={openScheduleModal}
            >
              Add ProAm Tournament
            </button>
          </div>
          {showSchedule && (
            <AddProamTournament
              isOpen={showSchedule}
              onClose={closeScheduleModal}
              refreshTournament={refreshTournament}
            />
          )}
          <table>
            <thead>
              <tr>
                <th>Tournament Name</th>
                <th>Actions</th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="schedule-container orange-scrollbar">
          <table>
            <tbody>
              {tournaments?.map((tournament) => (
                <tr key={tournament?.ParkId}>
                  <td>{tournament?.FirstName}</td>
                  <td>
                    <button
                      className="btn btn_primary text_upp"
                      onClick={() => onEdit(tournament?.ParkId)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
const Manage50Tournaments = () => {
  const [loading, setLoading] = useState(false);
  const [tournamentss, setTournaments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedParkId, setSelectedParkId] = useState(null);
  // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  // const getTournamentsHandler = async () => {
  //   setLoading(true);
  //   try {
  //     const {
  //       data: { data },
  //     } = await axios.get(API_URLS.GET_ALL_50_TOURNAMENTS);

  //     // Sort tournaments by ParkId in descending order (latest entry first)
  //     const sortedTournaments = data?.result[0].sort(
  //       (a, b) => b.ParkId - a.ParkId
  //     );

  //     setTournaments(sortedTournaments);
  //     console.log("sorted tournaments", sortedTournaments);

  //     setLoading(false);
  //   } catch (error) {
  //     alert(error.response.data.message);
  //     setLoading(false);
  //   }
  // };
  const openModal = (parkId) => {
    setSelectedParkId(parkId);
    setIsModalOpen(true);
    //console.log("state here", isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <div className="spinner" />
      </div>
    );
  }
  return (
    <Fragment>
      <TournamentTable
        onEdit={openModal}
        //refreshTournament={fnTournamentList}
      />
      {isModalOpen && (
        <Edit50Tournaments
          onClose={closeModal}
          isOpen={openModal}
          parkId={selectedParkId}
          //refreshTournament={fnTournamentList}
        />
      )}
    </Fragment>
  );
};

export { Manage50Tournaments };

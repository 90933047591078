import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import { API_URLS, BASE_URL } from "../../../../Utils";
import axios from "axios";

const EditTeamModel = ({
  isOpen,
  onClose,
  SelectedTeamId = null, // Default to null if missing
  ParkId = null,
  TourLeagueName = "Unknown",
  tournaments = [],
  getTeamsWithParkId = () => {},
}) => {
  const [formData, setFormData] = useState({
    TeamID: "",
    TeamName: "",
    CoachName: "",
    CoachEmail: "",
    CoachPassword: "",
    PlayingLeaguePark: "",
    PhoneNumber: "",
    Address: "",
    TourLeagueName: "",
    currentTourLeagueName: "",
    TeamLevel: "",
    LeagueLevel: "",
    Is60: false,
    IsInactive: false,
    IsActivated: false,
    NoOfGames: 0,
    SelectedDay: "",
    ParkId: "",
    TeamPhoto: "",
  });
  const [loading, setLoading] = useState(false);
  const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);
  const [imageName, setImageName] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const fileInputRef = useRef(null);

  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { TeamName, CoachName, CoachEmail, CoachPassword } = formData;
    if (!TeamName.trim()) {
      alert("Please Enter Team Name.", "", "info");
      return;
    }
    if (!CoachName.trim()) {
      alert("Please Enter Coach Name.", "", "info");
      return;
    }
    if (!CoachEmail.trim()) {
      alert("Please Enter Email ID.", "", "info");
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(CoachEmail.trim())) {
      alert("Please Enter a valid Email ID.", "", "info");
      return;
    }
    if (!CoachPassword.trim()) {
      alert("Please Enter Password.", "", "info");
      return;
    }
    try {
      const response = await axios.patch(API_URLS.UPDATE_TEAM, { formData });
      if (response.data.status === "success") {
        alert("Team Details Updated Successfully");
        getTeamsWithParkId(ParkId);
        onClose();
        setFormData({
          TeamID: "",
          TeamName: "",
          CoachName: "",
          CoachEmail: "",
          CoachPassword: "",
          PlayingLeaguePark: "",
          PhoneNumber: "",
          AddressCity: "",
          TourLeagueName: "",
          currentTourLeagueName: "",
          TeamLevel: "",
          LeagueLevel: "",
          Is60: false,
          IsInactive: false,
          IsActivated: false,
          NoOfGames: 0,
          SelectedDay: "",
          ParkId: "",
          TeamPhoto: "",
        });
      } else {
        throw new Error(response.message || "Failed to update team details");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while processing the request.");
    }
  };
  const getTeamInfo = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await axios.get(API_URLS.GET_TEAMS_BY_PARK_ID_AND_TEAM_ID, {
        params: {
          SelectedTeamId,
          ParkId,
          TourLeagueName,
        },
      });
      const result = data.result[0];
      setFormData({
        TeamID: result.TeamId,
        TeamName: result.TeamName,
        CoachName: result.CoachName,
        CoachEmail: result.CoachEmail,
        CoachPassword: result.CoachPassword,
        PlayingLeaguePark: result.PlayingLeaguePark,
        PhoneNumber: result.PhoneNumber,
        Address: result.Address,
        TourLeagueName: result.TourLeagueName,
        currentTourLeagueName: result.TourLeagueName,
        TeamLevel: result.TeamLevel,
        LeagueLevel: result.LeagueLevel,
        Is60: result.Is60,
        IsInactive: result.IsInactive,
        IsActivated: result.IsActivated,
        NoOfGames: result.NoOfGames,
        SelectedDay: result.SelectedDay,
        AgeLevel: result.AgeLevel,
        ParkId: ParkId,
        TeamPhoto: result.TeamPhoto,
      });
      setImageName(result.TeamPhoto || "");
      setLoading(false);
    } catch (error) {
      alert(error.response.data.message);
      setLoading(false);
    }
  };

  const handleImageChange = async (e) => {
    setIsRegisterDisabled(true);
    setTimeout(() => {
      setIsRegisterDisabled(false);
    }, 5000);
    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (upload) => {
          setImageName(upload.target.result);
        };
        reader.readAsDataURL(file);
        let data = new FormData();
        data.append("teamImage", file);
        const response = await axios.post(API_URLS.UPLOAD_TEAM_IMAGE, data);

        if (response.data.data.image.filename) {
          setFormData((prevState) => ({
            ...prevState,
            TeamPhoto: response.data.data.image.filename,
          }));
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (SelectedTeamId && ParkId && TourLeagueName) {
      getTeamInfo();
    }
  }, [SelectedTeamId, ParkId, TourLeagueName]);

  const customStyles = {
    content: {
      width: "80%", // Adjust this value to your desired width
      // Optional: Set a maximum width
      // Center the modal horizontally
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",
      margin: "auto",

      // Add some padding
      // Add other styles as needed
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Edit Team Modal"
      className="modal-content orange-scrollbar"
      overlayClassName="modal-overlay"
      style={{ ...customStyles }}
    >
      <div class="form_wrap teamReg_form ">
        <div className="close-button">
          <h3>Edit Team Details</h3>
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg "
            onClick={onClose}
          >
            <path
              d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
              fill="#1B1B1B"
            />
            <path
              d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
              fill="#1B1B1B"
            />
            <path
              d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
              fill="#1B1B1B"
            />
          </svg>
        </div>
        <form
          onSubmit={handleSubmit}
          className="row"
          style={{ marginRight: "10px", marginLeft: "1px" }}
        >
          <div className="col-md-6 input_group">
            <label htmlFor="TeamName">
              Team Name <span className="edd-required-indicator">*</span>
            </label>
            <input
              name="TeamName"
              value={formData.TeamName}
              onChange={handleChange}
              id="TeamName"
              className="input"
              placeholder="Team Name"
              type="text"
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="CoachName">
              Coach Name <span className="edd-required-indicator">*</span>
            </label>
            <input
              name="CoachName"
              id="CoachName"
              value={formData.CoachName}
              onChange={handleChange}
              className="input"
              placeholder="Coach Name"
              type="text"
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="CoachEmail">
              Coach Email <span className="edd-required-indicator">*</span>
            </label>
            <input
              name="CoachEmail"
              id="CoachEmail"
              value={formData.CoachEmail}
              onChange={handleChange}
              className="input"
              placeholder="Coach Email"
              type="email"
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="CoachPassword">
              Coach Password <span className="edd-required-indicator">*</span>
            </label>
            <input
              name="CoachPassword"
              id="CoachPassword"
              value={formData.CoachPassword}
              onChange={handleChange}
              className="input"
              placeholder="Coach Password"
              type="text"
            />
          </div>
          <div className="col-md-12 input_group">
            <label htmlFor="PlayingLeaguePark">
              Name of Park Your Team Plays League At
            </label>
            <input
              name="PlayingLeaguePark"
              id="PlayingLeaguePark"
              value={formData.PlayingLeaguePark}
              onChange={handleChange}
              className="input"
              placeholder="Park Name"
              type="text"
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="PhoneNumber">Phone Number</label>
            <input
              name="PhoneNumber"
              id="PhoneNumber"
              value={formData.PhoneNumber}
              onChange={handleChange}
              className="input"
              placeholder="Phone Number"
              type="text"
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="Address">Address (City,State)</label>
            <input
              name="Address"
              id="Address"
              value={formData.Address}
              onChange={handleChange}
              className="input"
              placeholder="City"
              type="text"
            />
          </div>
          {/* <div className="col-md-12 input_group">
            <label htmlFor="TourLeagueName">Tournament</label>
            <select
              id="TourLeagueName"
              name="TourLeagueName"
              value={formData.TourLeagueName}
              onChange={handleChange}
              className="input input_select"
            >
              <option value="">Select Tournament</option>
              {tournaments.map((item) => (
                <option value={item.FirstName}>{item.FirstName}</option>
              ))}
            </select>
          </div> */}
          <div className="col-md-6 input_group">
            <label htmlFor="TeamLevel">Team Level</label>
            <select
              id="TeamLevel"
              name="TeamLevel"
              value={formData.TeamLevel}
              onChange={handleChange}
              className="input input_select"
            >
              <option value="">Select Team Level</option>
              <option value="1">Level D</option>
              <option value="2">Level C</option>
              <option value="3">Level B</option>
              <option value="4">Level A</option>
              <option value="5">MAJOR</option>
            </select>
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="LeagueLevel">
              What Level of Play is the League?
            </label>
            <select
              id="LeagueLevel"
              name="LeagueLevel"
              value={formData.LeagueLevel}
              onChange={handleChange}
              className="input input_select"
            >
              <option value="">Select Level</option>
              <option value="1">A</option>
              <option value="2">B</option>
              <option value="3">C</option>
              <option value="4">D</option>
            </select>
          </div>
          <div className="col-md-12 input_group">
            <label htmlFor="AgeLevel">Age Level</label>
            <div id="AgeLevel" className="radio-group-horizontal">
              {["40", "50", "60", "65", "70", "75"].map((level) => (
                <label key={level} className="radio-label">
                  <input
                    type="radio"
                    name="AgeLevel"
                    value={level}
                    checked={String(formData.AgeLevel) === level}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        AgeLevel: e.target.value,
                      }))
                    }
                  />
                  {level}
                </label>
              ))}
            </div>
          </div>

          <div className="col-md-6 input_group">
            <label htmlFor="txtPhoto">Team Logo</label>
            <button
              className="btn btn_primary w-20"
              onClick={handleButtonClick}
            >
              Choose File
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            {(formData.TeamPhoto || imageName) && (
              <img
                src={
                  formData?.TeamPhoto
                    ? `${BASE_URL}/api/v1/files/TeamPhoto/${formData.TeamPhoto}`
                    : `${BASE_URL}/api/v1/files/TeamPhoto/${imageName}`
                }
                alt="Player"
                style={{ maxWidth: "150px" }}
              />
            )}
          </div>

          <p>
            <label htmlFor="Is60">
              <input
                type="checkbox"
                name="Is60"
                id="Is60"
                checked={formData.Is60}
                onChange={handleChange}
              />
              Is 60
            </label>
          </p>
          <p>
            <label htmlFor="IsInactive">
              <input
                type="checkbox"
                name="IsInactive"
                id="IsInactive"
                checked={formData.IsInactive}
                onChange={handleChange}
              />
              Is Inactive
            </label>
          </p>
          <p>
            <label htmlFor="IsActivated">
              <input
                type="checkbox"
                name="IsActivated"
                id="IsActivated"
                checked={formData.IsActivated}
                onChange={handleChange}
              />
              Is Activated
            </label>
          </p>

          <div className="col-md-12 btn_wrap">
            <button
              type="submit"
              className="btn btn_primary w-100"
              disabled={isRegisterDisabled}
            >
              {isRegisterDisabled ? "Wait to Upload the image" : "Update"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditTeamModel;
